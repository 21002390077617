<template>
  <div>
    <b-modal
      id="serviceRequestModal"
      size="lg"
      no-close-on-backdrop
      title="Service Request"
      :hide-footer="true"
      lazy
      @hidden="reset"
    >
      <b-overlay :show="loading" :opacity="0.6">
        <div>
          <b-row>
            <div class="d-flex flex-row flex-wrap align-content-start align-items-stretch no-gutters">
              <slot name="start"></slot>
              <div class="mr-4">
                <label class="col-head">Fleet Unit ID:</label>
                <p>{{ unit.fleetUnitId }}</p>
              </div>
              <div class="mr-4">
                <label class="col-head">VIN/Serial:</label>
                <p>{{ unit.vin }}</p>
              </div>
              <div class="mr-4">
                <label class="col-head">Year/Make/Model:</label>
                <p>{{ unit.year }} {{ unit.make }} {{ unit.model }}</p>
              </div>
              <div class="mr-4">
                <label class="col-head">Location:</label>
                <p>{{ unitLocation }}</p>
              </div>
            </div>
          </b-row>
          <label class="col-head">Branch:</label>
          <p>{{ branchDisplay }}</p>
          <b-table v-if="showOperations && !loading" striped :fields="operationFields" :items="operations">
            <!-- Select Column -->
            <template #head(selected)>
              <div class="text-center">
                <input v-model="selectAll" type="checkbox" />
              </div>
            </template>
            <template #cell(selected)="{ item }">
              <div class="text-center">
                <input v-model="selectedOps" type="checkbox" :value="item" />
              </div>
            </template>
            <template #cell(name)="{ item }">
              <div v-if="item.operationStatus != 'Deferred'">
                {{ item.name }}
              </div>
              <div v-else>{{ item.jobId }} - {{ item.complaint }}</div>
            </template>
            <!-- Operation Status Column -->
            <template #cell(operationStatus)="{ value }">
              <b-badge v-show="value" :variant="CssHelper.getOperationStatusCssClass(value)" class="mr-1">
                {{ value }}
              </b-badge>
            </template>
          </b-table>

          <!-- Customer Comments -->
          <div v-if="$isCustomer" class="my-2">
            <b-form-group label="Comments:" label-class="col-head" label-for="comments" class="pb-2">
              <div class="d-flex">
                <b-textarea
                  id="customerComments"
                  v-model="customerComments"
                  class="overflow-auto rounded"
                  size="sm"
                  rows="1"
                  max-rows="4"
                />
              </div>
            </b-form-group>
          </div>

          <!-- DATE/TIME SECTION -->
          <b-form-group v-if="$isCustomer" class="pb-2" label-for="service-request-date">
            <div class="d-flex">
              <date-time-input
                id="service-request-date"
                v-model="date"
                placeholder="MM/DD/YYYY"
                label="Service Date Requested:"
                label-class="col-head"
                format="MM/dd/yyyy"
                class="d-inline-block"
                typeable
                :validator="$v.date"
                :disabled-dates="disabledDates"
                hide-time
                required
              >
                <template #default>
                  <div v-if="$v.$dirty && !$v.date.required" class="error">Request a Service Date</div>
                </template>
              </date-time-input>
            </div>
          </b-form-group>
          <div v-show="showSummary">
            <b>
              <h5>Summary:</h5>
              <div class="d-flex flex-column">
                <div>{{ getComplaint() }}</div>
                <div>{{ branchDisplay }}</div>
                <div v-if="date">{{ date | date }}</div>
              </div>
            </b>
          </div>

          <div class="pt-4">
            <b-button v-show="!sending" variant="primary" @click="sendRequest">
              <span v-if="$isCustomer">Send Request</span>
              <span v-else>
                Schedule Service
                <font-awesome-icon icon="external-link-alt" fixed-width />
              </span>
            </b-button>
            <b-button v-show="sending" disabled>
              <b-spinner small></b-spinner>
              Sending...
            </b-button>
            <b-button variant="secondary ml-3" @click="cancel">
              <div>Cancel</div>
            </b-button>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
//components
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
// vuex
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { UnitActions, UnitGetters, UnitMutations } from '@/shared/store/unit/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UserGetters } from '@/shared/store/user/types';
import { ConfigGetters } from '@/shared/store/config/types';
import { DeferredJobActions, DeferredJobGetters } from '@/shared/store/deferred-job/types';

//Services
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import SchedulerService from '@/shared/services/SchedulerService';
// helpers
import moment from 'moment';
import CssHelper from '@/shared/helpers/operation-status-css-class-helper';
import { requiredIf } from 'vuelidate/lib/validators';

export default {
  name: 'ServiceRequestModal',
  components: {
    'date-time-input': DateTimeInput
  },
  data() {
    return {
      CssHelper,
      unitId: null,
      loading: false,
      sending: false,
      selectedOps: [],
      customerComments: null,
      date: null,
      operationFields: [
        { key: 'selected', label: '', tdClass: 'align-middle' },
        {
          key: 'name',
          label: 'Operation',
          tdClass: 'align-middle',
          thClass: 'text-left'
        },
        { key: 'operationStatus', label: 'Status', tdClass: 'align-middle' }
      ]
    };
  },
  validations: {
    date: {
      required: requiredIf(function () {
        return this.$isCustomer;
      })
    }
  },
  computed: {
    ...mapGetters({
      config: ConfigGetters.GET_CONFIG,
      unit: UnitGetters.GET_UNIT,
      unitLocation: UnitGetters.GET_UNIT_LOCATION_NAME,
      scheduledOperations: UnitGetters.GET_UNIT_STANDARD_OPERATIONS,
      unitDeferredJobs: DeferredJobGetters.GET_UNIT_DEFERRED_JOBS,
      user: UserGetters.GET_USER_PROFILE,
      branchesMap: LookupGetters.GET_MY_BRANCHES_MAP,
      locationsMap: LookupGetters.GET_LOCATIONS_MAP
    }),
    branchId() {
      const unitLocation = this.locationsMap[this.unit.locationId];
      return unitLocation?.branchId;
    },
    branchDisplay() {
      if (!this.unit.locationId || !this.branchesMap) return 'N/A';
      const branch = this.branchesMap[this.branchId];
      return branch?.description ?? 'N/A';
    },
    selectAll: {
      get() {
        return this.selectedOps.length == this.operations.length;
      },
      set(value) {
        this.selectedOps = [];
        if (value) {
          this.operations.forEach(item => {
            this.selectedOps.push(item);
          });
        }
      }
    },
    operations() {
      let operations = [];
      this.scheduledOperations.forEach(oper => {
        operations.push(oper);
      });
      this.unitDeferredJobs.forEach(oper => {
        oper.operationStatus = 'Deferred';
        operations.push(oper);
      });

      return operations;
    },
    operationsList() {
      return this.selectedOps.map(x => x.name).join(',');
    },
    showSummary() {
      // TODO add if date/time is selected
      //   return this.selectedOps.length > 0;
      return !this.loading;
    },
    disabledDates() {
      return { to: this.minimumDate };
    },
    minimumDate() {
      return moment(this.dateCreate).startOf('day').toDate();
    },
    showOperations() {
      return this.operations.length > 0;
    }
  },
  methods: {
    ...mapMutations([UnitMutations.SET_UNIT, UnitMutations.SET_UNIT_STANDARD_OPERATIONS]),
    ...mapActions([
      UnitActions.FETCH_UNIT,
      UnitActions.FETCH_UNIT_STANDARD_OPERATIONS,
      DeferredJobActions.FETCH_UNIT_DEFERRED_JOBS
    ]),
    selectDueAndOverDue() {
      for (let operation of this.scheduledOperations) {
        if (operation.operationStatus === 'Due' || operation.operationStatus === 'Overdue') {
          this.selectedOps.push(operation);
        }
      }
    },
    selectDeferredJob(jobId) {
      this.operations.forEach(oper => {
        if (oper.jobId === jobId) {
          this.selectedOps.push(oper);
        }
      });
    },
    async showAndLoad(unitId, jobId) {
      this[UnitMutations.SET_UNIT]({});
      this[UnitMutations.SET_UNIT_STANDARD_OPERATIONS]([]);
      this.show(unitId);
      this.loading = true;
      try {
        await this[UnitActions.FETCH_UNIT](unitId);
        await this[UnitActions.FETCH_UNIT_STANDARD_OPERATIONS](unitId);
        await this[DeferredJobActions.FETCH_UNIT_DEFERRED_JOBS]({ vin: this.unit.vin });
        if (jobId != null) {
          this.selectDeferredJob(jobId);
        }
        this.selectDueAndOverDue();
      } finally {
        this.loading = false;
      }
    },
    async show(unitId) {
      this.date = null;
      this.$v.$reset();
      this.unitId = unitId;
      this.$bvModal.show('serviceRequestModal');
      this.selectDueAndOverDue();
    },
    getComplaint() {
      if (this.selectedOps.length === 0) return '';
      if (this.selectedOps.length > 0) {
        return this.selectedOps
          .filter(unitOperation =>
            this.operations.some(operation => unitOperation.standardOperationId === operation.standardOperationId)
          )
          .map(unitOperation =>
            unitOperation.operationStatus === 'Pending Schedule' ||
            unitOperation.operationStatus === 'Pending Service' ||
            unitOperation.operationStatus === 'Current'
              ? unitOperation.name + ' Due'
              : unitOperation.name + ' ' + unitOperation.operationStatus
          )
          .join(', ');
      } else {
        return '';
      }
    },
    async sendRequest() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error with Service request. See indicated fields below.');
        return;
      }

      const request = {
        units: [
          {
            unitId: this.unitId,
            serviceOrderId: null,
            standardOperationId: this.selectedOps.map(x => (x.standardOperationId ? x.standardOperationId : null))
          }
        ],
        complaint: this.getComplaint(),
        branchId: this.branchId,
        contactEmail: this.user.email,
        contactName: this.user.name,
        sourceId: this.$isCustomer ? SchedulerService.sources.customer : SchedulerService.sources.vams,
        customerComments: this.customerComments,
        serviceDateRequested: this.date
      };
      try {
        this.sending = true;
        await SchedulerService.requestService([request]);
        this.cancel();
        if (!this.$isCustomer) {
          const url = this.config.shopSchedulerUrl;
          window.open(`${url}/schedule/${this.branchId}`);
        } else {
          SuccessService.createSuccessToast(this.$root, `Service request sent.`);
        }
      } catch (error) {
        ErrorService.createErrorToast(this.$root, `Error sending service request.`);
      } finally {
        this.sending = false;
      }
    },
    reset() {
      this.unitId = null;
      this.customerComments = null;
      this.selectedOps = [];
    },
    cancel() {
      this.$bvModal.hide('serviceRequestModal');
    }
  }
};
</script>
